import React from "react"
import { Layout } from "../components"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { StaticImage } from "gatsby-plugin-image"

const documents = [
  {
    title: "Jaarrapportage 2023",
    date: "18 feb 2024",
    url: "/documenten/2024-02-10-De-Spetter-Jaarrapportage-2023-DEF.pdf",
  },
  {
    title: "Jaarrapportage 2022",
    date: "31 dec 2022",
    url: "/documenten/Jaarrapportage_2022.pdf",
  },
  {
    title: "Jaarrapportage 2021",
    date: "31 dec 2021",
    url: "/documenten/deSpetter_jaarrapportage_2021.pdf",
  },
  {
    title: "Jaarrapportage 2020",
    date: "31 dec 2020",
    url: "/documenten/Jaarrapportage_2020.pdf",
  },
  {
    title: "Jaarrapportage 2019",
    date: "31 dec 2019",
    url: "/documenten/deSpetter_jaarrapportage_2019.pdf",
  },
  {
    title: "Jaarrapportage 2018",
    date: "31 dec 2018",
    url: "/documenten/deSpetter_jaarrapportage_2018.pdf",
  },
  {
    title: "Beheer en bedrijfsplan 2018",
    date: "31 dec 2018",
    url: "/documenten/20171017-Def.versie college_beheer- en bedrijfsplan_Spetter_2018-2028.pdf",
  },
  {
    title: "Handtekeningen Flyer (nov.2014)",
    date: "04 nov 2014",
    url: "/documenten/041306-handtekeningenflyer_blijvenspetteren.pdf",
  },
  {
    title: "Brief gemeenteraadsleden ( nov. 2014)",
    date: "01 nov 2014",
    url: "/documenten/20141101-brief_gemeenteraadsleden.pdf",
  },
  {
    title: "Renovatieadvies",
    date: "26 mei 2014",
    url: "/documenten/Renovatieadvies_Blijven_Spetteren.pdf",
  },
  {
    title: "Inspreken Johan koedoed (jun.2013)",
    date: "19 jun 2013",
    url: "/documenten/130619_inspreken_johankoedood_blijvenspetteren.pdf",
  },
  {
    title: "Discussienotitie Synarchis (jun 2013)",
    date: "06 jun 2013",
    url: "/documenten/20141101-brief_gemeenteraadsleden.pdf",
  },
  {
    title: "Ebook Gezonde Wijk",
    date: "03 dec 2008",
    url: "/documenten/081203-eboek_gezondewijk.pdf",
  },
]

const Documenten = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Documenten"
        description="Documenten van zwembadraad blijven spetteren"
      />
      <div className="container prose prose-lg prose-slate mx-auto max-w-5xl">
        <h2 className="">Documenten</h2>
        <table>
          <thead>
            <tr>
              <th>Titel</th>
              <th>Datum bijgewerkt</th>
              <th className="hidden md:block">Downloaden</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((document, index) => (
              <tr>
                <td>
                  <StaticImage
                    className=""
                    src="../assets/images/pdf.svg"
                    alt="PDF Logo"
                    placeholder="blurred"
                    width={40}
                  />
                  <a
                    className="no-underline hover:underline"
                    href={document.url}
                  >
                    <span className="ml-2">{document.title}</span>
                  </a>
                </td>
                <td>{document.date}</td>
                <td className="hidden md:block">
                  <a
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-spetter px-4 py-2 font-semibold capitalize text-white no-underline transition hover:bg-green-700 focus:border-red-700 focus:outline-none focus:ring focus:ring-red-200 active:bg-green-700 disabled:opacity-25"
                    href={document.url}
                    download
                  >
                    Downloaden
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default Documenten
